import React from 'react';
import './Penalty.css'
import CssBaseline from '@material-ui/core/CssBaseline';
import { Grid } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';






export default function Penalty(){


    return (
        <div className="root">
            <CssBaseline />    
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <div className="avatarblock">
                    <p className="description ad">                  
                        <span className="jss574 jss575" data-ga-event-category="ad" data-ga-event-action="click" data-ga-event-label="in-house-carbon-body-top-image">
                            <span className="jss579">
                                <a href="https://gumroad.com/l/wta-how-to-heal-book/15" target="_blank" rel="noopener sponsored" data-ga-event-category="ad" data-ga-event-action="click" data-ga-event-label="in-house-tidelift">
                                    <span className="jss580">
                                        <img height="100" width="130" src="../images/ad/wta_book_cover.png" alt="tidelift"/>
                                    </span>
                                    <span className="jss581">
                                        <p>Constant jerk off can cause a drop in overall <b> Intellect, Concentration problem, Laziness, Hairloss and dark circles </b></p>
                                        <b>Click here to learn how to recover from wasting sexual energy due to masterbation or intercourse</b>.  
                                    </span>
                                </a>
                            </span>
                        </span>
                    </p> 
                        <Typography variant="h2" gutterBottom>
                            Caught you.  No Porn!
                        </Typography>
                            <img src="../images/erotic_white.png" height="180" width="180"/>
                        <Typography className="exp" variant="h4" gutterBottom>
                            <span>
                                <ArrowDownwardIcon fontSize="large" />
                            </span>
                              EXP
                        </Typography>
                    </div>
                </Grid>
            </Grid>
        </div> 
    );
}
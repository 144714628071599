import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';

const useStyles = makeStyles({
  root: {
    width: '100%',
    maxWidth: 1000,
    margin: 'auto',
    '& p':{
        textAlign: 'left',
    },
    '& ul li':{
        listStyle:'decimal',
        padding: '5px',
        textAlign: 'left',
    }
  },
});


export default function Privacy() {
    const classes = useStyles();

  
    return (
      <div className={classes.root}>
            <Typography variant="h3" gutterBottom>
                PRIVACY NOTICE
            </Typography>
            <Typography variant="h6" gutterBottom>
                Last updated April 11, 2023
            </Typography>
            <Typography variant="body1" gutterBottom>
                Thank you for choosing to be part of our community at The Warrior Tribe (“company”, “we”, “us”, or “our”). We are committed to protecting your personal information and your right to privacy. If you have any questions or concerns about our policy, or our practices with regards to your personal information, please contact us at support@thewarriortribe.xyz
            </Typography>
            <br/>
            <Typography variant="body1" gutterBottom>
                When you visit our website,   
                <Link color="blue" href="https://www.thewarriortribe.xyz">
                    https://www.thewarriortribe.xyz
                </Link>
                 , and use our services on Shopify for FestivalKing with API requests to <Link color="blue" href="https://www.festivalking.xyz">https://www.festivalking.xyz</Link>
                , you trust us with your personal information. We take your privacy very seriously. In this privacy notice, we describe our privacy policy. We seek to explain to you in the clearest way possible what information we collect, how we use it and what rights you have in relation to it. We hope you take some time to read through it carefully, as it is important. If there are any terms in this privacy policy that you do not agree with, please discontinue use of our Sites and our services.
            </Typography>
            <br/>
            <Typography variant="body1" gutterBottom>
                This privacy policy applies to all information collected through our website 
                (such as <Link color="blue" href="https://www.thewarriortribe.xyz">
                           https://www.thewarriortribe.xyz
                        </Link> and our services on Shopify for FestivalKing with API requests to <Link color="blue" href="https://www.festivalking.xyz">https://www.festivalking.xyz</Link>),
                and/or any related services, sales, marketing or events (we refer to them collectively in this privacy policy as the "Sites").
            </Typography>
            <br/>

            <Typography variant="body1" gutterBottom>
                <b> Please read this privacy policy carefully as it will help you make informed decisions about sharing your personal information with us. </b>
            </Typography>
            <br/>

            <Typography variant="h5" gutterBottom>
                1. WHAT INFORMATION DO WE COLLECT?
            </Typography>
            <br/>
            <Typography variant="h6" gutterBottom>
                Information collected from other sources 
            </Typography>
            <Typography variant="body1" gutterBottom>
                <i>In Short: We may collect limited data from public databases, marketing partners, and other outside sources.</i>  
            </Typography>
            <br/>
            <Typography variant="body1" gutterBottom>
                We may obtain information about you from other sources, such as public databases, joint marketing partners, as well as from other third parties. Examples of the information we receive from other sources include: social media profile information; marketing leads and search results and links, including paid listings (such as sponsored links). 
            </Typography>
            <br/>

            <Typography variant="h5" gutterBottom>
                2. HOW DO WE USE YOUR INFORMATION?  
            </Typography>
            <Typography variant="body1" gutterBottom>
                <i>In Short: We process your information for purposes based on legitimate business interests, the fulfillment of our contract with you, compliance with our legal obligations, and/or your consent.</i>  
            </Typography>
            <br/>
            <Typography variant="body1" gutterBottom>
                We may obtain information about you from other sources, such as public databases, joint marketing partners, as well as from other third parties. Examples of the information we receive from other sources include: social media profile information; marketing leads and search results and links, including paid listings (such as sponsored links). 
            </Typography>
            <br/>
            <Typography variant="body1" gutterBottom>
                We use the information we collect or receive:  
           </Typography>
           <br/>

            <ul>
                <li>To facilitate account creation and logon process.If you choose to link your account with us to a third party account *(such as your Google or Facebook account), we use the information you allowed us to collect from those third parties to facilitate account creation and logon process. </li>
                <li>To send you marketing and promotional communications. We and/or our third party marketing partners may use the personal information you send to us for our marketing purposes, if this is in accordance with your marketing preferences. You can opt-out of our marketing emails at any time (see the "WHAT ARE YOUR PRIVACY RIGHTS" below).</li>
                <li>To send administrative information to you. We may use your personal information to send you product, service and new feature information and/or information about changes to our terms, conditions, and policies. </li>
                <li>Fulfill and manage your orders. We may use your information to fulfill and manage your orders, payments, returns, and exchanges made through the Sites.  </li>
                <li>To post testimonials. We post testimonials on our Sites that may contain personal information. Prior to posting a testimonial, we will obtain your consent to use your name and testimonial. If you wish to update, or delete your testimonial, please contact us at project.converging.point+nofap@gmail.com and be sure to include your name, testimonial location, and contact information. </li>
                <li>Deliver targeted advertising to you. We may use your information to develop and display content and advertising (and work with third parties who do so) tailored to your interests and/or location and to measure its effectiveness.</li>
                <li>Administer prize draws and competitions. We may use your information to administer prize draws and competitions when you elect to participate in competitions.</li>
                <li>Request Feedback. We may use your information to request feedback and to contact you about your use of our Sites. </li>
                <li>To protect our Sites. We may use your information as part of our efforts to keep our Sites safe and secure (for example, for fraud monitoring and prevention).</li>
                <li>To enable user-to-user communications. We may use your information in order to enable user-to-user communications with each user's consent. </li>
                <li>To enforce our terms, conditions and policies.</li>
                <li>To respond to legal requests and prevent harm. If we receive a subpoena or other legal request, we may need to inspect the data we hold to determine how to respond.</li>
                <li>For other Business Purposes. We may use your information for other Business Purposes, such as data analysis, identifying usage trends, determining the effectiveness of our promotional campaigns and to evaluate and improve our Sites, products, services, marketing and your experience. </li>
            </ul>  



            <Typography variant="h5" gutterBottom>
                3. WILL YOUR INFORMATION BE SHARED WITH ANYONE?    
            </Typography>
            <Typography variant="body1" gutterBottom>
                <i>In Short: We only share information with your consent, to comply with laws, to protect your rights, or to fulfill business obligations.</i>  
            </Typography>
           <br/>

            <ul>
                <li>Consent: We may process your data if you have given us specific consent to use your personal information in a specific purpose. </li>
                <li>Legitimate Interests: We may process your data when it is reasonably necessary to achieve our legitimate business interests. </li>
                <li>Performance of a Contract: Where we have entered into a contract with you, we may process your personal information to fulfill the terms of our contract. </li>
                <li>Legal Obligations: We may disclose your information where we are legally required to do so in order to comply with applicable law, governmental requests, a judicial proceeding, court order, or legal process, such as in response to a court order or a subpoena (including in response to public authorities to meet national security or law enforcement requirements). </li>
                <li>Vital Interests: We may disclose your information where we believe it is necessary to investigate, prevent, or take action regarding potential violations of our policies, suspected fraud, situations involving potential threats to the safety of any person and illegal activities, or as evidence in litigation in which we are involved.</li>
                <li>Vendors, Consultants and Other Third-Party Service Providers. We may share your data with third party vendors, service providers, contractors or agents who perform services for us or on our behalf and require access to such information to do that work. Examples include: payment processing, data analysis, email delivery, hosting services, customer service and marketing efforts. We may allow selected third parties to use tracking technology on the Sites, which will enable them to collect data about how you interact with the Sites over time. This information may be used to, among other things, analyze and track data, determine the popularity of certain content and better understand online activity. Unless described in this Policy, we do not share, sell, rent or trade any of your information with third parties for their promotional purposes. </li>
                <li>Business Transfers. We may share or transfer your information in connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company.  </li>
            </ul> 

            <br/>

            <Typography variant="h5" gutterBottom>
                4. WHO WILL YOUR INFORMATION BE SHARED WITH?     
            </Typography>
            <Typography variant="body1" gutterBottom>
                <i>In Short:  We only share information with the following third parties.</i>  
            </Typography>
           <br/>
           <Typography variant="body1" gutterBottom>
           We only share and disclose your information with the following third parties. We have categorized each party so that you may be easily understand the purpose of our data collection and processing practices. If we have processed your data based on your consent and you wish to revoke your consent, please contact us.
            </Typography>
            <br/>

            <ul>
                <li>Functionality and Infrastructure Optimisation Termly.io</li>
                <li>Invoice and Billing Stripe</li>
                <li>Web and Mobile Analytics Google Analytics</li>
            </ul> 

            <br/>

            <Typography variant="h5" gutterBottom>
                5. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?     
            </Typography>
            <Typography variant="body1" gutterBottom>
                <i>In Short: We may use cookies and other tracking technologies to collect and store your information.</i>  
            </Typography>
           <br/>
             <Typography variant="body1" gutterBottom>
                We may use cookies and similar tracking technologies (like web beacons and pixels) to access or store information. Specific information about how we use such technologies and how you can refuse certain cookies is set out in our Cookie Policy. 
            </Typography>
    
            <br/>









      </div>
    );
  }
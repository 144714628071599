import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import './App.css';
import Footer from './components/Footer/Footer'


// Theme
import { createTheme, ThemeProvider } from '@material-ui/core/styles';


// Pages
import Landing from './components/Landing/Landing';
import Uninstall from './components/Uninstall/Uninstall';
import Privacy from './components/Privacy/Privacy';
import Pricing from './components/Pricing/Pricing';
import Cancel from './components/Cancel/Cancel';
import Success from './components/Success/Success';
import Penalty from './components/Penalty/Penalty';
import Navigation from './components/Navigation/Navigation'
import ProfileCreationSteps from './components/Creation/ProfileCreationSteps'

function App() {
  const theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          type: 'dark',
          primary: {
            main: '#4fc3f7',
          },
          secondary: {
            main: '#81d4fa',
          },
        },
      }),
  );
  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <Router>
          <Switch>
          <Route exact path="/" >
              <Landing/>  
            </Route>
            <Route exact path="/uninstall/:email" render={(props) => <Uninstall {...props} /> } />
            <Route exact path="/privacy" >
              <Privacy/>  
            </Route>
            <Route exact path="/pricing/:email">
              <Pricing/>  
            </Route>
            <Route exact path="/success">
              <Success/>  
            </Route>
            <Route exact path="/cancel" >
              <Cancel/>  
            </Route>
            <Route exact path="/penalty">
              <Penalty />  
            </Route>
            <Route exact path="/creation/profile/:email" render={(props) => <ProfileCreationSteps {...props} /> } />
            <Route exact path="/profile/:email" render={(props) => <Navigation {...props} /> } />
          </Switch>
        </Router>
        <Footer />
      </div>
    </ThemeProvider>
  );
}

export default App;

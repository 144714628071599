import React, {useState} from 'react';
import Grid from '@material-ui/core/Grid';
import CssBaseline from '@material-ui/core/CssBaseline';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';



const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    marginTop: '120px'
  },
  gridBlock: {
      display:'block'
  },
  avatar: {
      margin: '55px auto 55px auto',
      width: theme.spacing(20),
      height: theme.spacing(20),
  },
  avatarblock:{
      display:'inline-block',
      '& h4': {
        paddingTop:'25px'
      }
  },
  nameFiled: {
      width: '25ch',
      marginBottom: '5ch'
  },
  button:{
    margin: '20px auto',
    display: 'block'
  },
  feedbackField:{
    width: '600px',
    height: '150px',
    marginTop: '20px',
    fontSize: 'x-large'
  }
}));

export default function Uninstall(props) {
    const classes = useStyles();
    const REQUEST_URL = process.env.REACT_APP_API_URL + 'feedbacks/' + props.match.params.email;
    const [feedback, setFeedback] = useState('');


    const handleChange = (_event) => {
      let value = _event.target.value;
      setFeedback(value);
    }

    const handleSubmit  = async (_event) => {
      _event.preventDefault();
        let req = {
            feedback: {
                message: feedback
            }
        };
        const response = await fetch(REQUEST_URL,{
        method:'POST',
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(req)
        });
        const data = await response.json();
        alert('submitted, thank you.' + props.match.params.email);
    }


    return (
      <div className={classes.root}>
            <CssBaseline />
            <Grid className={classes.gridBlock} container spacing={3}>
                <div className={classes.avatarblock}>
                    <Typography variant="h4" gutterBottom>
                      It's sad to see you go...
                    </Typography>
                    <Avatar alt="Profile Avatar" src="../../images/sad_face.png" className={classes.avatar} />
                    <Typography variant="h5" gutterBottom>
                      Let's us know why you are leaving so we can make it better.
                    </Typography>
                    
                    <textarea onChange={handleChange} className={classes.feedbackField} />

                    <Button onClick={handleSubmit} color="primary" className={classes.button}>
                      Submit
                    </Button>
                      
                </div>
            </Grid>
        </div>
    );
  }
import React, {forwardRef} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import CssBaseline from '@material-ui/core/CssBaseline';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import Input from '@material-ui/core/Input';



const useStyles = makeStyles(theme => ({
    root: {
      flexGrow: 1,
      marginTop: '120px'
    },
    gridBlock: {
        display:'block'
    },
    avatar: {
        margin: '55px auto 55px auto',
        width: theme.spacing(20),
        height: theme.spacing(20),
    },
    avatarblock:{
        display:'inline-block',
        '& h4': {
          paddingTop:'25px'
        }
    },
    nameFiled: {
        width: '25ch',
        marginBottom: '5ch'
    }
  }));

function ProfileCreation(props, ref) {
    const classes = useStyles();
    const REQUEST_URL = process.env.REACT_APP_API_URL + 'users/name';

    let updateName = async (_event) => {
        let name = _event.target.value;
        let email = props.match.params.email;
        let req = {
            data: {
                email: email,
                username: name
            }
        };
        const response = await fetch(REQUEST_URL,{
        method:'POST',
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(req)
        });
        const data = await response.json();
    }

    return (
        <div className={classes.root}>
            <CssBaseline />
            <Grid className={classes.gridBlock} container spacing={3}>
                <div className={classes.avatarblock}>
                    <Typography variant="h4" gutterBottom>
                    Welcome, please enter the name for your Avatar
                    </Typography>
                    <Avatar alt="Profile Avatar" src="../../images/player_icon.png" className={classes.avatar} />
                    <Input required ref={ref} defaultValue="" onBlur={updateName} className={classes.nameFiled} inputProps={{ 'aria-label': 'description' }} />
                </div>
            </Grid>
        </div>
    );

}

export default forwardRef(ProfileCreation)
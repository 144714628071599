import React, {useEffect, useState} from 'react';
import {makeStyles, withStyles} from '@material-ui/core/styles';
import FireplaceIcon from '@material-ui/icons/Fireplace';
import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import LinearProgress from '@material-ui/core/LinearProgress';
import CssBaseline from '@material-ui/core/CssBaseline';
import Alert from '@material-ui/lab/Alert';
import 'dotenv/config'
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";



const useStyles = makeStyles(theme => ({
    linkcolor: {
        color: 'white'
    },
    root: {
        flexGrow: 1,
        marginTop: '120px'
    },
    large: {
        width: theme.spacing(20),
        height: theme.spacing(20),
    },
    avatarblock: {
        display: 'inline-block',
        '& h4': {
            paddingTop: '30px'
        }
    },
    margin: {
        margin: theme.spacing(5),
    },
    info: {
        marginTop: '65px'
    },
    alert: {
        marginBottom: '3em',
        textAlign: 'left',
    },
    warning:{
        color: 'red'
    }
}));

const BorderLinearProgress = withStyles({
    root: {
        height: 20,
    },
    bar: {
        borderRadius: 20,
    },
})(LinearProgress);

export default function Profile(props) {
    const REQUEST_URL = process.env.REACT_APP_API_URL + 'users/' + props.match.params.email;
    const PAYMENT_URL = process.env.REACT_APP_API_URL + props.match.params.email;
    const [level, setLevel] = useState(1);
    const [current_exp, setCEXP] = useState(0);
    const [next_exp, setNEXP] = useState(0);
    const [completionValue, setCV] = useState(0);
    const [streak, setStreak] = useState(0);
    const [name, setName] = useState('');
    const [plan, setPlan] = useState('');
    const [subscription_date, setSubscriptionDate] = useState('');


    useEffect(() => {
        getUserProfile();
    }, []);

    let percentageConverter = (_current_exp, _next_exp) => {
        let division = (_current_exp / _next_exp);
        return (division * 100);
    };

    const getUserProfile = async () => {
        const response = await fetch(REQUEST_URL, {
            method: 'GET',
            mode: 'cors', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            headers: {
                'Content-Type': 'application/json'
            }
        });
        const data = await response.json();
        setLevel(data.data.level);
        setCEXP(data.data.current_exp);
        setNEXP(data.data.next_lv_exp);
        setName(data.data.name);
        setPlan(data.data.plan);
        setSubscriptionDate(data.data.subscription_date_moment_format);
        let percentage = percentageConverter(data.data.current_exp, data.data.next_lv_exp);
        setCV(percentage);
        setStreak(data.data.streak);
    };




    const classes = useStyles();

    const AlertsMarkup = () => (
        <Alert severity="error" className={classes.alert}>Your subscription has expired. Please click <a
            href={PAYMENT_URL}>here</a> to subscribe.
            Otherwise, the plugin won't block sites properly.
        </Alert>
    )

    let streakMarkup = (streak > 1) ?
        <Typography variant="h3" gutterBottom>
            {streak} day streak <FireplaceIcon fontSize="large" color="secondary"/>
        </Typography> : '';

    return (
        <div className={classes.root}>
            <CssBaseline/>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                    <div className={classes.avatarblock}>
                        <Typography variant="h4" gutterBottom>
                            {name}
                        </Typography>
                        <Avatar alt="Profile Avatar" src="../images/player_icon.png" className={classes.large}/>
                        <Typography variant="h4" gutterBottom>
                            LV {level}
                        </Typography>
                    </div>
                </Grid>
                <Grid item xs={12} sm={6} className={classes.info}>
                    <div className={classes.progress}>
                        <Typography variant="h4" gutterBottom>
                            {current_exp} / {next_exp} Exp
                        </Typography>
                        <BorderLinearProgress
                            className={classes.margin}
                            variant="determinate"
                            color="secondary"
                            value={completionValue}/>
                        {streakMarkup}
                        <Typography variant="h4" gutterBottom>
                            Subscription
                        </Typography>

                        {plan === 'expired' ? (
                                <Typography variant="h5" gutterBottom className={classes.warning}>
                                    Your subscription is expired. Please subscribe to continue using the plugin. Otherwise
                                    the plugin won't block sites properly and the avatar won't level up.
                                </Typography>
                            ) : (
                                <Typography variant="h5" gutterBottom>
                                    {plan}
                                </Typography>
                            )
                        }
                        {plan === 'expired' &&
                            <Button variant="outlined" color="primary">
                                <Link className={classes.linkcolor} to={"/pricing/" + props.match.params.email}>Subscribe</Link>
                            </Button>
                        }
                        { (plan !== "trail" && plan !== "expired" && plan !== "free") &&
                            <Typography variant="h4" gutterBottom>
                                Subscription Date {subscription_date}
                            </Typography>
                        }

                    </div>
                </Grid>
            </Grid>
        </div>
    );
}

import React from 'react';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import 'dotenv/config'



const useStyles = makeStyles(theme => ({
  '@global': {
    ul: {
      margin: 0,
      padding: 0,
      listStyle: 'none',
    },
  },
  appBar: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  toolbar: {
    flexWrap: 'wrap',
  },
  toolbarTitle: {
    flexGrow: 1,
  },
  link: {
    margin: theme.spacing(1, 1.5),
  },
  heroContent: {
    padding: theme.spacing(8, 0, 6),
  },
  cardHeader: {
    backgroundColor:
      theme.palette.type === 'dark' ? theme.palette.grey[700] : theme.palette.grey[200],
  },
  cardPricing: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'baseline',
    marginBottom: theme.spacing(2),
  },
  footer: {
    borderTop: `1px solid ${theme.palette.divider}`,
    marginTop: theme.spacing(8),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.up('sm')]: {
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(6),
    },
  },
  warning: {
    color: 'red',
    fontSize: '20px',
    textAlign: 'left'
  }
}));

const tiers = [
  {
    title: '1 Month',
    price: '2.99',
    type: 'monthly',
    currency: '/mo',
    description: [],
    buttonText: 'Subscribe',
    buttonVariant: 'outlined',
  },
  {
    title: '12 Months',
    subheader: 'Save $4.00 per year',
    price: '20.00',
    type: 'annual',
    currency: '/year',
    description: [
      'Best Value'
    ],
    buttonText: 'Subscribe',
    buttonVariant: 'contained',
  },
  {
    title: '6 Months',
    subheader: 'Save $2.99 per year',
    price: '10.00',
    currency: '/6 mos',
    type: 'half-year',
    description: [
    ],
    buttonText: 'Subscribe',
    buttonVariant: 'outlined',
  },
];
const footers = [
  {
    title: 'Company',
    link: 'mailto:project.converging.point+nofap@gmail.com',
    description: [ 'Contact us'],
  },
  {
    title: 'Legal',
    link: 'mailto:project.converging.point+nofap@gmail.com',
    description: ['Privacy policy'],
  },
];


export default function Pricing() {
  //monthly test "https://buy.stripe.com/test_dR6eVK0lHfV2bG8144"
  //half-year test "https://buy.stripe.com/test_bIYbJy7O96ks6lO28b"
  //annual test "https://buy.stripe.com/test_bIY5lafgB10811u8wA"
  const MONTHLY = "https://buy.stripe.com/eVa9Bh1jpa897yE3cc";
  const HALF_YEAR = "https://buy.stripe.com/aEUbJp0fla897yE7st";
  const ANNUAL = "https://buy.stripe.com/aEUdRxbY3fst1agaEG";
  const classes = useStyles();



  function checkoutComplete(data) {
    console.log(data);
    alert('Thanks for your purchase.');
  }

  let handleSubscription = async (_plan_type) => {
    let url = '';
    switch (_plan_type) {
      case 'monthly':
        url = MONTHLY;
        break;
      case "half-year":
        url = HALF_YEAR;
        break;
      case "annual":
        url = ANNUAL;
        break;
        default:
          break;
    }
    window.location.href = url;

    //  Paddle.Checkout.open({
    //     product: _plan_id,
    //     successCallback: function(data) {
    //     let checkoutId = data.checkout.id;
    //
    //     Paddle.Order.details(checkoutId, async function(data) {
    //       // Order data, downloads, receipts etc... available within 'data' variable.
    //       let planTitle = data.checkout.title;
    //       let clientEmail = data.order.customer.email;
    //       let requestObj = {
    //         user: {
    //           email: clientEmail,
    //           plan: planTitle
    //         }
    //       };
    //
    //       const response = await fetch(REQUEST_URL,{
    //         method:'POST',
    //         mode: 'cors', // no-cors, *cors, same-origin
    //         cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    //         credentials: 'same-origin', // include, *same-origin, omit
    //         headers: {
    //           'Content-Type': 'application/json'
    //         },
    //         body: JSON.stringify(requestObj)
    //       });
    //       const returnData = await response.json();
    //
    //       if (returnData.data === 'updated') {
    //         window.location.href = "https://pornbuster.xyz/success";
    //       }
    //
    //     });
    //
    //   }
    // });
  }

  return (
    <React.Fragment>
      <CssBaseline />
      {/* Hero unit */}
      <Container maxWidth="sm" component="main" className={classes.heroContent}>
        <Typography component="h1" variant="h2" align="center" color="textPrimary" gutterBottom>
          Mr.NoFap Subscription
        </Typography>
      </Container>
      {/* End hero unit */}
      <Container maxWidth="md" component="main">
        <Grid container spacing={5} alignItems="flex-end">
          {tiers.map(tier => (
            // Enterprise card is full width at sm breakpoint
            <Grid item key={tier.title} xs={12} sm={tier.title === 'Enterprise' ? 12 : 6} md={4}>
              <Card>
                <CardHeader
                  title={tier.title}
                  subheader={tier.subheader}
                  titleTypographyProps={{ align: 'center' }}
                  subheaderTypographyProps={{ align: 'center' }}
                  action={tier.title === 'Pro' ? <StarBorderIcon /> : null}
                  className={classes.cardHeader}
                />
                <CardContent>
                  <div className={classes.cardPricing}>
                    <Typography component="h2" variant="h3" color="textPrimary">
                      ${tier.price}
                    </Typography>
                    <Typography variant="h6" color="textSecondary">
                      ${tier.currency}
                    </Typography>
                  </div>
                  <ul>
                    {tier.description.map(line => (
                      <Typography component="li" variant="subtitle1" align="center" key={line}>
                        {line}
                      </Typography>
                    ))}
                  </ul>
                </CardContent>
                <CardActions>
                  <Button onClick={()=> handleSubscription(tier.type)} fullWidth variant={tier.buttonVariant} color="primary">
                    {tier.buttonText}
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
      <Container maxWidth="sm" className={classes.heroContent}>
        <Typography component="h4" variant="h4" align="center" className={classes.warning} gutterBottom>
          The payment will show as *internet productivity tool* on your bill
        </Typography>
      </Container>
    </React.Fragment>
  );
}